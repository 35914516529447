import React from 'react'
import Layout from "../components/Layout"
import Seo from './Seo'
import { Link, graphql } from 'gatsby'
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import '../assets/css/iniciativapoststyle.css'

export default function Iniciativas({data: {allContentfulIniciativas:{nodes}}}) {
    const { titulo, descripcion, miniatura, content, pdfdoc } = nodes[0]
    const { intro, puntos } = content
    const imageInicitiva = getImage(miniatura.gatsbyImageData)

    return (
        <Layout>
            <Seo title="Iniciativa" />
            <div className="iniciativaPost">
                <div className="imgBox">
                    <GatsbyImage  
                        className='ini-img' 
                        image={imageInicitiva} alt='imagen'/>
                </div>
                <div className="iniPostBody">
                    <h1 className='ini-titulo text-4xl'>{titulo}</h1>
                    <p className='ini-desc  text-2xl'>{descripcion.descripcion}</p>
                    <h3 className='ini-titulopuntos'>{intro}</h3>
                    <ul className='ini-puntos'>
                        {
                            puntos.map((punto, index)=>{
                               return <li className='text-2xl' key={index}>{punto}</li>
                            })    
                        }
                    </ul>
                    {pdfdoc != 'none' ? (
                        <div>
                            <h3 className='mt-8 ini-titulopuntos'>¿Deseas Leer el Documento? </h3>
                            <Link
                                target='_blank'
                                to={pdfdoc}
                                className="smtBtm cursor-pointer text-lg rounded p-4 text-white 
                                                uppercase font-bold"    
                            
                            >Descarga esta Iniciativa en PDF</Link>
                        </div>
                    ):''}

                </div>
            </div>
        </Layout>
    )
}

export const query = graphql`
    query($id:String!){
        allContentfulIniciativas(filter: {id: {eq: $id}}) {
            nodes {
                id
                titulo
                slug
                pdfdoc
                descripcion {
                    id
                    descripcion
                  }
                  content {
                    id
                    intro
                    puntos
                  }
                miniatura {
                    gatsbyImageData(
                        placeholder: BLURRED, 
                        formats: [AUTO,PNG,WEBP]
                    )
                }
            }
        }
    }    
    `
